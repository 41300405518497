<template>
    <div>
        <div class="header">
            <div class="header-down">
                <img class="phone" src="../assets/jiemian.png" alt="">
                <div class="code-row">
                    <h3>矩阵优选小程序</h3>
                    <span>随时随地 拿起赚钱</span>
                    <img src="../assets/xiaochenxu.jpg" alt="">
                    <span class="open">扫码进入小程序</span>
                </div>
            </div>
        </div>
        <div class="ref-content">
            <div class="ref-text">
                <h3>短视频达人，一键带货</h3>
                <p class="p">只要你玩短视频，你就可以随时随地拿起惠民带货APP发视频带货赚钱。</p>
            </div>
            <img class="res-images" src="../assets/ifone.png" alt="">
        </div>
        <div style="background-color:#F5F5F5;">
            <div style="width: 1240px; margin: 0 auto;display: flex;align-items: center;">
                <img class="res-img" src="../assets/20210924181911.png" alt="">
                <div class="ref-text">
                    <h3>带货视频，随时领取</h3>
                    <p class="p">通过短视频带货变现没素材？没关系，拍客们提供了大量视频供您领取。</p>
                </div>

            </div>

        </div>

    </div>
</template>

<script>
    export default {
        name: "intelligent"
    }
</script>

<style scoped lang="scss">
    .header{
        width: 100%;
        background: url("../assets/bgc-down.jpg") no-repeat;
        background-size: cover;
        padding: 1px 0;
        box-sizing: border-box;
        height: 680px;
    }
    .header-down{
        display: flex;
        justify-content: space-between;
        /*align-items: center;*/
        width: 1200px;
        min-height: 100vh;
        margin: 10px auto 0;
        .phone{
            width: 500px;
            height: 671px;
            margin-left:100px ;
            /*box-shadow: 10px 10px 10px black;*/
        }
        .code-row{
            display: flex;
            flex-direction: column;
            /*align-items: center;*/
            /*justify-content: space-around;*/
            /*width: 200px;*/
            padding: 20px;
            margin-top: 30px;
            color: white;
            span{
                font-size: 28px;
                font-weight: 400;
                letter-spacing: 2px;
            }
            h3{
                font-size: 48px;
            }
            img{
                width: 150px;
                height: 150px;
                /*margin-top: 20px;*/
                padding: 50px 0 10px;
            }
            .open{
                font-size: 18px;
                text-indent: 5px;
            }
        }
    }
    .ref-content{
        display: flex;
        width: 100%;
        /*height: 800px;*/
        padding-top: 170px;
    }
    .ref-content-bottom{
        display: flex;
        width: 100%;
        height: 800px;
        padding-top: 170px;
    }
    .ref-text {
        width: 580px;
        padding-top: 118px;
        h3{
            color: #222;
            font-size: 48px;
            line-height: 64px;
            letter-spacing: 1px;
            font-weight: 600;
        }
        p{
            color: #777777;
            font-size: 26px;
            line-height: 48px;
            font-weight: 200;
            margin-top: 40px;
        }
    }
    .ref-content{
        width:1200px;
        margin: 0 auto;
    }
    .ref-content .res-images {
        width: 396px;
        height: 630px;
        margin-left: 120px;
    }
    .res-img{
        padding: 0;
        margin-left: 0;
        margin-right: 120px;
        width: 563px;
    }
</style>
